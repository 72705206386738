import axios from 'axios';
import { getBaseURL, getCurrentLang } from './Network';

// live
export const baseUrl = 'https://api.thegreenmatter.com/';
export const bosnianBaseUrl = `https://apibsn.scraption.net/`;

// test
// export const baseUrl = 'http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6047/';
// export const bosnianBaseUrl = `http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6057/`;

export function getResource(path, onSuccess, onFail) {
  const base = getBaseURL();

  let requestData = {
    method: 'get',
    url: base + path,
    headers: {
      'Accept-Language': getCurrentLang(),
    },
  };

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      onFail(fail.response);
    });
}

export function postResource(path, data, onSuccess, onFail, region = "eg") {
  const base = region === "eg" ? baseUrl : bosnianBaseUrl;
  
  let requestData = {
    method: 'post',
    url: base + path,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': getCurrentLang(),
    },
    data: data,
  };

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      onFail(fail.response);
    });
}
