// import { useState } from 'react';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../Store/Language';
import { CheckOutlined } from '@ant-design/icons';

// components
import LanguageIcon from './LanguageIcon';

const LanguageBtn = ({ navBtn, hideBtnText, region }) => {
  // const [toggle, setToggle] = useState(false);
  const { language } = useSelector((state) => state.lang);
  // const dir = language === 'ar' ? 'rtl' : 'ltr';
  const dispatch = useDispatch();

  const handleLanguageChange = (value) => {
    dispatch(setLanguage(value));
  };

  const languages = [
    { label: 'English', key: 'en' },
    { label: 'عربي', key: 'ar' },
    { label: 'Bosnian', key: 'hr' },
  ];

  return (
    <Dropdown
      menu={{
        items: languages
          .filter((lang) => {
            if (region === 'eg' && lang.key === 'hr') {
              return false;
            } else if (region === 'bs' && lang.key === 'ar') {
              return false;
            }
            return true;
          })
          .map((lang) => ({
            key: lang.key,
            label: (
              <div
                dir={lang.key === 'ar' ? 'rtl' : 'ltr'}
                onClick={() => handleLanguageChange(lang.key)}
                className="language-btn__dropdown-holder__item d-flex justify-content-between align-items-center gap-1"
                style={{
                  color: language === lang.key ? '#60EC2F' : '#000',
                }}
              >
                <span>{lang.label}</span>
                <span>{language === lang.key && <CheckOutlined />}</span>
              </div>
            ),
          })),
      }}
      trigger={['click']}
      overlayClassName="language-btn__dropdown-holder"
    >
      <button
        type="button"
        className={`language-btn language-btn${
          navBtn ? '--nav' : '--footer'
        } d-flex justify-content-center align-items-center bg-transparent border-0 outline-none`}
      >
        <LanguageIcon />
        {!hideBtnText ? (
          <span
            style={{
              color: '#fff',
              fontsize: '16px',
              lineHeight: '21px',
              fontWeight: '500',
            }}
          >
            {language === 'ar'
              ? 'عربي'
              : language === 'hr'
              ? 'BS'
              : language.toUpperCase()}
          </span>
        ) : (
          ''
        )}
      </button>
    </Dropdown>

    /**************/
    /* OLD BUTTON */
    /**************/
    // <button
    //   onClick={() => setToggle((pre) => !pre)}
    //   type="button"
    //   className="language-btn language-btn--nav"
    // >
    //   <LanguageIcon />
    //   <p
    //     style={{
    //       color: '#fff',
    //       fontsize: '16px',
    //       lineHeight: '21px',
    //       fontWeight: '500',
    //     }}
    //   >
    //     {language === 'ar'
    //       ? 'عربي'
    //       : language === 'hr'
    //       ? 'BS'
    //       : language.toUpperCase()}
    //   </p>
    //   {toggle && (
    //     <ul className="language-list" dir={dir}>
    //       {languages.map((lang) => (
    //         <li
    //           onClick={() => handleLanguageChange(lang.value)}
    //           key={lang.value}
    //           style={{
    //             color: language === lang.value ? '#60EC2F' : '#000',
    //           }}
    //         >
    //           <span>{lang.label}</span>
    //           <span>{language === lang.value && <CheckOutlined />}</span>
    //         </li>
    //       ))}
    //     </ul>
    //   )}
    // </button>
  );
};

export default LanguageBtn;
