import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// assets
import logoEn from '../../Assets/Images/logo-en.png';
import logoAr from '../../Assets/Images/logo-ar.png';
import burgerMenu from '../../Assets/Icons/Navbar/burger-menu.svg';

// components
import NavbarLink from './NavbarLink';
import LanguageBtn from '../LanguageBtn';
import HomeIcon from './Icons/HomeIcon';
import AboutIcon from './Icons/AboutIcon';
// import TermsIcon from './Icons/TermsIcon';
// import PrivacyIcon from './Icons/PrivacyIcon';
import ContactIcon from './Icons/ContactIcon';
import StoresIcon from './Icons/StoresIcon';
import SupplyPricesIcon from './Icons/SupplyPricesIcon';

const Navbar = ({ onOpenTermsModal }) => {
  const [expanded, setExpanded] = useState(false);
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const location = useLocation();
  const navToggleBtn = useRef();
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const navLinks = [
    { key: 1, name: t('navbar.home'), link: 'home', icon: <HomeIcon /> },
    {
      key: 2,
      name: t('navbar.aboutUs'),
      link: 'about-us',
      icon: <AboutIcon />,
    },
    {
      key: 6,
      name: t('navbar.ourWarehouses'),
      link: 'stores',
      icon: <StoresIcon />,
    },
    {
      key: 7,
      name: t('navbar.scrapPrices'),
      link: 'supply-prices',
      icon: <SupplyPricesIcon />,
    },
    {
      key: 5,
      name: t('navbar.contactUs'),
      link: 'contact-us',
      icon: <ContactIcon />,
    },
    // {
    //   key: 3,
    //   name: t('navbar.terms'),
    //   // link: 'terms-and-conditions',
    //   link: '',
    //   icon: <TermsIcon />,
    // },
    // {
    //   key: 4,
    //   name: t('navbar.privacyPolicy'),
    //   link: 'privacy-policy',
    //   icon: <PrivacyIcon />,
    // },
  ];

  const handleCloseMenu = () => {
    navToggleBtn.current.click();
    setExpanded(!expanded);
  };

  const handleChangingPath = (path) => {
    if (location.pathname.slice(1) === path) {
      window.scrollTo(0, 0);
    }

    // condtion for small screens menu
    if (expanded) {
      handleCloseMenu();
    }
  };

  const handleOpenMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <header dir={dir} className="header bg--main-blue">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid px-0">
          <Link className="navbar-brand py-0" to="/home">
            <img
              src={language === 'ar' ? logoAr : logoEn}
              alt=""
              className="navbar__logo"
            />
          </Link>
          <button
            className="navbar-toggler p-0 border-0 outline-none shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={navToggleBtn}
            onClick={handleOpenMenu}
          >
            <img src={burgerMenu} alt="" className="navbar-toggler__icon" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {navLinks.map((ele) => (
                <li
                  key={ele.key}
                  className="nav-item"
                  onClick={() => handleChangingPath(ele.link)}
                >
                  {ele.link ? (
                    <NavbarLink
                      title={ele.name}
                      icon={ele.icon}
                      path={ele.link}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={onOpenTermsModal}
                      className="nav-link bg-transparent border-0 outline-none shadow-none px-2 d-inline-flex flex-lg-column align-items-center"
                    >
                      {ele.icon}
                      <span className="nav-link__title fsize--7 text-capitalize text--white">
                        {ele.name}
                      </span>
                    </button>
                  )}
                </li>
              ))}
              <li className="nav-item">
                <LanguageBtn navBtn={true} />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
