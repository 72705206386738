import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// assets
import googleStore from '../Assets/Images/google-play.png';
import appleStore from '../Assets/Images/app-store.png';
import logoEn from '../Assets/Images/logo-en.png';
import logoAr from '../Assets/Images/logo-ar.png';
import facebookIcon from '../Assets/Icons/Footer/social--facebook.svg';
import instagramIcon from '../Assets/Icons/Footer/social--insta.svg';
import linkedInIcon from '../Assets/Icons/Footer/social--linkedin.svg';
import tiktokIcon from '../Assets/Icons/Footer/social--tiktok.svg';

// components
import LanguageBtn from './LanguageBtn';

const Footer = ({ onOpenTermsModal }) => {
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const location = useLocation();
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const navLinks = [
    { key: 1, name: t('navbar.home'), link: '/home' },
    { key: 2, name: t('navbar.aboutUs'), link: '/about-us' },
    { key: 3, name: t('navbar.terms'), link: '' },
    { key: 4, name: t('navbar.privacyPolicy'), link: '/privacy-policy' },
    { key: 5, name: t('navbar.contactUs'), link: '/contact-us' },
  ];
  const socialLinks = [
    {
      key: 1,
      label: 'Facebook',
      link: 'https://www.facebook.com/share/159gTMWW4o/?mibextid=wwXIfr',
      icon: facebookIcon,
    },
    {
      key: 2,
      label: 'Instagram',
      link: 'https://www.instagram.com/bigscrappers.eg?igsh=MW5pNzZmNDN3dGdibQ==',
      icon: instagramIcon,
    },
    {
      key: 3,
      label: 'LinkedIn',
      link: 'https://www.linkedin.com/company/big-scrappers/',
      icon: linkedInIcon,
    },
    {
      key: 4,
      label: 'Tik Tok',
      link: 'https://www.tiktok.com/@big.scrappers?_t=ZS-8t01QuQjQ5y&_r=1',
      icon: tiktokIcon,
    },
  ];

  const handleChangingPath = (path) => {
    if (location.pathname === path) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <footer dir={dir} className="footer">
      <div className="footer__holder mx-auto">
        <ul className="footer__holder__nav-links d-flex flex-wrap align-items-center justify-content-center list-unstyled">
          {navLinks.map((ele) => (
            <li
              key={ele.key}
              className={`${language === 'ar' ? 'lang--ar' : 'lang--en'}`}
            >
              {ele.link ? (
                <Link
                  to={ele.link}
                  onClick={() => handleChangingPath(ele.link)}
                  className="text--white fsize--6 text-capitalize fw-bold"
                >
                  {ele.name}
                </Link>
              ) : (
                <button
                  type="button"
                  onClick={onOpenTermsModal}
                  className="bg-transparent border-0 outline-none shadow-none text--white fsize--6 text-capitalize fw-bold"
                >
                  {ele.name}
                </button>
              )}
            </li>
          ))}
          <li>
            <LanguageBtn />
          </li>
        </ul>
        <div dir="ltr" className="row m-0 flex-column-reverse flex-xl-row">
          <div className="footer__holder__copy-rights-col col-xl-6 px-0 d-flex justify-content-center justify-content-xl-start">
            <div className="d-flex flex-column">
              <img
                src={language === 'ar' ? logoAr : logoEn}
                alt="Big Scrappers logo"
                className="footer__holder__copy-rights-col__logo img-fluid d-block mx-auto"
              />
              <p
                className="text--white text-center fsize--6 fw-bold mb-0"
                style={{ width: language === 'hr' ? 'max-content' : '' }}
              >
                {t('footer.bigScrappersWebsite')} <br /> 2024
              </p>
              <ul className="footer__holder__social-links d-flex align-items-center justify-content-center mb-0 list-unstyled">
                {socialLinks.map((ele) => (
                  <li key={ele.key}>
                    <Link
                      to={ele.link}
                      aria-label={`${ele.label} Link`}
                      target="_blank"
                    >
                      <img
                        src={ele.icon}
                        alt={ele.label}
                        className="d-block img-fluid"
                      />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer__holder__get-app-links-col col-xl-6 px-0 d-flex justify-content-center justify-content-xl-end">
            <div className="footer__holder__get-app-links-col__holder d-flex flex-column align-items-center">
              <p className="text--white fsize--7 fw-bold text-center mb-0">
                {t('home.thirdSection.getApplication')}
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.nationtech.bigscrappers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={googleStore} alt="" className="d-block img-fluid" />
              </a>
              <a
                href="https://apps.apple.com/eg/app/big-scrappers/id6557068840"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appleStore} alt="" className="d-block img-fluid" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
