import { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import parseMax, { isValidPhoneNumber } from 'libphonenumber-js/max';
import { allowNumbersOnly } from '../../Helpers/HelpfulFunction';

// assets
import egyptFlag from '../../Assets/Icons/egypt-flag.svg';
import bosnaFlag from '../../Assets/Icons/bosna-flag.svg';

const PhoneInput = ({ form }) => {
  const [selectedCode, setSelectedCode] = useState('20');
  const { language } = useSelector((state) => state.lang);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const handlePhoneValidition = (_, value) => {
    const country = selectedCode === '20' ? 'EG' : 'BA';
    const maxAcceptedLength = selectedCode === '20' ? 11 : 8;
    if (!value) {
      return Promise.reject(new Error(''));
    } else if (
      !/^\d+$/.test(value) ||
      !isValidPhoneNumber(value, country) ||
      value.length > maxAcceptedLength ||
      (country === 'BA' && !value.startsWith('6'))
    ) {
      return Promise.reject(new Error(''));
    } else if (parseMax(value, country).getType() !== 'MOBILE') {
      return Promise.reject(new Error());
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (form?.getFieldValue('mobile')) {
      form.validateFields(['mobile']);
    }
  }, [selectedCode, form]);

  useEffect(() => {
    if (searchParams) {
      const region = searchParams?.get('region');
      setSelectedCode(region === 'eg' ? '20' : '387');
    }
  }, [searchParams]);

  return (
    <div dir="ltr" className="invite-friends__holder__form__phone-input-holder">
      <div className="row m-0">
        <div className="col-4 ps-0 pe-md-4">
          <div className="invite-friends__holder__form__phone-input-holder__country-select h-100 d-flex align-items-center justify-content-center">
            <img
              src={selectedCode === '20' ? egyptFlag : bosnaFlag}
              alt=""
              className="img-fluid me-1"
            />
            <span className="fsize--6 fw-bold">(+{selectedCode})</span>
          </div>
        </div>
        <div className="col-8 pe-0">
          <Form.Item
            name="mobile"
            className="mb-0"
            rules={[
              {
                validator: handlePhoneValidition,
              },
            ]}
          >
            <Input
              placeholder={t('inviteFriends.enterPhoneNumber')}
              className="invite-friends__holder__form__phone-input-holder__input fsize--6 text--dark-blue"
              onKeyDown={allowNumbersOnly}
              data-lang={language}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default PhoneInput;
