import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form } from 'antd';
import { claimInviteFriendOffer } from '../APIs/Network';
import { toastError } from '../Helpers/Toasters';

// assets
import illustration from '../Assets/Images/invite-friends.png';

// components
import PhoneInput from './../Components/InviteFriends/PhoneInput';
import LanguageBtn from '../Components/LanguageBtn';

const InviteFriends = () => {
  const [submittable, setSubmittable] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [searchParams] = useSearchParams();
  const { language } = useSelector((state) => state.lang);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const initialValues = { mobile: '', countryCode: '20' };

  const checkIfURLParamsAreValid = () => {
    const region = searchParams?.get('region');
    const code = searchParams?.get('id');
    return code && region && (region === 'eg' || region === 'bs');
  };

  const onFinish = (values) => {
    const areParamsValid = checkIfURLParamsAreValid();
    if (submitting || !areParamsValid) {
      return;
    }
    setSubmitting(true);

    const region = searchParams.get('region');
    const payload = {
      code: searchParams.get('id'),
      phone: `${
        region === 'eg' ? `2${values.mobile.length < 11 ? '0' : ''}` : '387'
      }${values.mobile}`,
    };

    claimInviteFriendOffer(
      { payload, region },
      (res) => {
        setSubmitting(false);
        if (res.success) {
          window.location.href = res.data;
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        setSubmitting(false);
        toastError(res?.data?.error);
      }
    );
  };

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        (info) => {
          const areParamsValid = checkIfURLParamsAreValid();
          if (areParamsValid) {
            setSubmittable(true);
          } else {
            setSubmittable(false);
          }
        },
        (info) => {
          if (info.errorFields.length === 0) {
            setSubmittable(true);
          } else {
            setSubmittable(false);
          }
        }
      );
  }, [values, form, searchParams]);

  useEffect(() => {
    if (searchParams && form) {
      const region = searchParams?.get('region');
      form.setFieldValue('countryCode', region === 'eg' ? '20' : '387');
    }
  }, [searchParams, form]);

  return (
    <section className="invite-friends flex-fill d-flex flex-column align-items-lg-center justify-content-lg-center">
      <div className="invite-friends__holder">
        <header className="invite-friends__holder__header position-relative d-flex justify-content-center align-items-center">
          <div
            data-lang={language}
            className="invite-friends__holder__header__lang-btn-holder position-absolute"
          >
            <LanguageBtn
              navBtn={true}
              hideBtnText={true}
              region={searchParams?.get('region')}
            />
          </div>
          <img src={illustration} alt="" className="d-block img-fluid" />
        </header>
        <div className="invite-friends__holder__body d-flex flex-column">
          <h1 className="fw-bold fsize--5 mb-0">
            {t('inviteFriends.heading')}
          </h1>
          <p className="fsize--6 mb-0">{t('inviteFriends.enterPhone')}</p>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            className="invite-friends__holder__form d-flex flex-column"
          >
            <PhoneInput form={form} />
            <p className="fsize--6 mb-0">{t('inviteFriends.note')}</p>
            <Button
              htmlType="submit"
              disabled={!submittable}
              loading={submitting}
              className="invite-friends__holder__form__submit-btn border-0 fw-bold fsize--6"
            >
              {t('inviteFriends.claimOffer')}
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default InviteFriends;
