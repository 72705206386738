import { useState, useEffect } from 'react';
import { Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { pdfjs, Document, Page } from 'react-pdf';
import { getBaseURL, getTermsAndConditions } from '../APIs/Network';
import { toastError } from '../Helpers/Toasters';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TermsModal = ({ open, onCancel }) => {
  const [terms, setTerms] = useState('');
  const [pdfPages, setPdfPages] = useState(0);
  const { language } = useSelector((state) => state.lang);
  const { openTerms } = useSelector((state) => state.ui);

  const { t } = useTranslation();

  useEffect(() => {
    if (open || openTerms) {
      getTermsAndConditions(
        (res) => {
          if (res.success) {
            setTerms(res.data);
          } else {
            toastError(res?.message);
          }
        },
        (res) => {
          toastError(res?.message);
        }
      );
    }
  }, [language, open, openTerms]);

  return (
    <Modal
      centered
      title={
        <h1
          dir={language === 'en' ? 'ltr' : 'rtl'}
          className="text-capitalize text-center text--green fw-bold fsize--3"
        >
          {t('navbar.terms')}
        </h1>
      }
      width={
        window.innerWidth >= 992
          ? '65vw'
          : window.innerWidth >= 550
          ? '85vw'
          : '95vw'
      }
      rootClassName="terms-modal"
      maskClosable={false}
      wrapClassName="terms-modal__wrap"
      open={open || openTerms}
      onCancel={onCancel}
      closable={true}
      footer={[]}
    >
      <div className="mx-auto">
        <Document
          onLoadSuccess={(obj) => setPdfPages(obj.numPages)}
          file={`${getBaseURL()}${terms}?noCache=${Date.now()}`}
          loading={
            <div className="position-absolute top-50 start-50 translate-middle">
              <Spin size="large" />
            </div>
          }
          noData={'No data...'}
        >
          {pdfPages
            ? Array(pdfPages)
                .fill(0)
                .map((page, indx) => (
                  <Page
                    key={indx}
                    pageNumber={indx + 1}
                    className={'mx-auto d-flex justify-content-center'}
                    loading=""
                    scale={
                      window.innerWidth >= 768
                        ? 1
                        : window.innerWidth >= 500
                        ? 0.8
                        : 0.6
                    }
                  />
                ))
            : ''}
        </Document>
      </div>
    </Modal>
  );
};

export default TermsModal;
